export const menu_admin = [
  {
    component: 'CNavItem',
    name: 'Accueil',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  //Scolarité
  {
    component: 'CNavTitle',
    name: 'Scolarité',
  },
  {
    component: 'CNavGroup',
    name: 'Inscription',
    to: '/inscription',
    icon: 'cil-plus',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/inscription/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/inscription/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Classe',
    to: '/classe',
    icon: 'cil-house',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/classe/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/classe/nouveau',
      },
    ],
  },
  /*{
    component: 'CNavGroup',
    name: 'Matière',
    to: '/matiere',
    icon: 'cil-book',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/matiere/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/matiere/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Enseignant',
    to: '/enseignant',
    icon: 'cil-user-x',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/enseignant/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/enseignant/nouveau',
      },
    ],
  },*/
  {
    component: 'CNavGroup',
    name: 'Elève',
    to: '/eleve',
    icon: 'cil-group',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/eleve/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/eleve/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Parent',
    to: '/parent',
    icon: 'cil-wc',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/parent/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/parent/nouveau',
      },
    ],
  },
  //comptabilité
  {
    component: 'CNavTitle',
    name: 'Comptabilité',
  },
  {
    component: 'CNavGroup',
    name: 'Frais de scolarité',
    to: '/frais-scolarite',
    icon: 'cil-money',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/frais-scolarite/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/frais-scolarite/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Paiement',
    to: '/paiement',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/paiement/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/paiement/nouveau',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Rapport',
    to: '/rapport-dettes',
    icon: 'cil-chart',
  },
  //Admin
  {
    component: 'CNavTitle',
    name: 'Administration',
  },
  /*{
    component: 'CNavItem',
    name: 'Ecole',
    to: '/ecole',
    icon: 'cil-education',
  },*/
  {
    component: 'CNavGroup',
    name: 'Année académique',
    to: '/annee-academique',
    icon: 'cil-av-timer',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/annee-academique/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/annee-academique/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Utilisateur',
    to: '/utilisateur',
    icon: 'cil-user',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/utilisateur/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/utilisateur/nouveau',
      },
    ],
  },
  // {
  //   component: 'CNavGroup',
  //   name: 'Profil',
  //   to: '/profil',
  //   icon: 'cil-tag',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Liste',
  //       to: '/profil/liste',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Nouveau',
  //       to: '/profil/nouveau',
  //     },
  //   ],
  // },
  /*{
    component: 'CNavGroup',
    name: 'Vaccination',
    to: '/vaccination',
    icon: 'cil-medical-cross',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/vaccination/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/vaccination/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Paramètres',
    to: '/parametre',
    icon: 'cil-cog',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/parametre/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/parametre/nouveau',
      },
    ],
  },*/
  //Incription
  // {
  //   component: 'CNavTitle',
  //   name: 'Inscription',
  // },
]

export const menu_secretariat = [
  {
    component: 'CNavItem',
    name: 'Accueil',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  //Scolarité
  {
    component: 'CNavTitle',
    name: 'Scolarité',
  },
  {
    component: 'CNavGroup',
    name: 'Inscription',
    to: '/inscription',
    icon: 'cil-plus',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/inscription/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/inscription/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Classe',
    to: '/classe',
    icon: 'cil-house',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/classe/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/classe/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Elève',
    to: '/eleve',
    icon: 'cil-group',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/eleve/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/eleve/nouveau',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Parent',
    to: '/parent',
    icon: 'cil-wc',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/parent/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/parent/nouveau',
      },
    ],
  },
  //comptabilité
  {
    component: 'CNavTitle',
    name: 'Comptabilité',
  },
  {
    component: 'CNavGroup',
    name: 'Frais de scolarité',
    to: '/frais-scolarite',
    icon: 'cil-money',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/frais-scolarite/liste',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Paiement',
    to: '/paiement',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Liste',
        to: '/paiement/liste',
      },
      {
        component: 'CNavItem',
        name: 'Nouveau',
        to: '/paiement/nouveau',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Rapport',
    to: '/rapport-dettes',
    icon: 'cil-chart',
  },
]
