<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
      <!--<CAvatar :src="avatar" size="md" />-->
      <CIcon icon="cil-user" /> {{ SetUserName() }}
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"
      >
      </CDropdownHeader>
      <CDropdownItem @click="Profil()">
        <CIcon icon="cil-user" /> Votre compte
      </CDropdownItem>
      <CDropdownItem @click="Logout()">
        <CIcon icon="cil-lock-locked" /> Se déconnecter
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
export default {
  name: 'AppHeaderDropdownAccnt',
  methods: {
    Logout() {
      localStorage.clear()
      this.$router.push('/login')
    },
    Profil() {
      this.$router.push('/utilisateur/profil')
    },
    SetUserName() {
      const user = JSON.parse(localStorage.getItem('user'))
      return user.nom + ' ' + user.prenom
    },
  },
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
}
</script>
