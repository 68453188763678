import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { authGuard } from '@/_helpers/auth-guard'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Tableau de bord',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/ecole',
        name: 'Ecole',
        component: () => import('@/views/ecole/EcoleView.vue'),
      },
      {
        path: '/annee-academique',
        name: 'Année académique',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/annee-academique/liste',
        children: [
          {
            path: '/annee-academique/liste',
            name: 'Liste des années académiques',
            component: () => import('@/views/annee/AnneeAcademique.vue'),
          },
          {
            path: '/annee-academique/nouveau',
            name: 'Nouvelle année académique',
            component: () => import('@/views/annee/NewAnnee.vue'),
          },
          {
            path: '/annee-academique/edition',
            name: 'Modifier une année académique',
            component: () => import('@/views/annee/NewAnnee.vue'),
          },
        ],
      },
      {
        path: '/utilisateur',
        name: 'Utilisateur',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/utilisateur/liste',
        children: [
          {
            path: '/utilisateur/liste',
            name: 'Liste des utilisateurs',
            component: () => import('@/views/utilisateur/User.vue'),
          },
          {
            path: '/utilisateur/nouveau',
            name: 'Créer un utilisateur',
            component: () => import('@/views/utilisateur/UserNew.vue'),
          },
          {
            path: '/utilisateur/edition',
            name: 'Modifier un utilisateur',
            component: () => import('@/views/utilisateur/UserNew.vue'),
          },
          {
            path: '/utilisateur/profil',
            name: 'Mon profil',
            component: () => import('@/views/utilisateur/UserProfil.vue'),
          },
        ],
      },
      {
        path: '/profil',
        name: 'Profil',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/profil/liste',
        children: [
          {
            path: '/profil/liste',
            name: 'Liste des profils',
            component: () => import('@/views/profil/ProfilList.vue'),
          },
          {
            path: '/profil/nouveau',
            name: 'Créer un profil',
            component: () => import('@/views/profil/ProfilNew.vue'),
          },
          {
            path: '/profil/edition',
            name: 'Editer un profil',
            component: () => import('@/views/profil/ProfilNew.vue'),
          },
        ],
      },
      {
        path: '/inscription',
        name: 'Inscription',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/inscription/liste',
        children: [
          {
            path: '/inscription/liste',
            name: 'Liste des inscriptions',
            component: () => import('@/views/inscription/InscriptionList.vue'),
          },
          {
            path: '/inscription/nouveau',
            name: 'Nouvelle inscription',
            component: () => import('@/views/inscription/NewInscription.vue'),
          },
          {
            path: '/inscription/edition',
            name: 'Editer une inscription',
            component: () => import('@/views/inscription/NewInscription.vue'),
          },
          {
            path: '/inscription/detail',
            name: 'Détails inscription',
            component: () =>
              import('@/views/inscription/DetailsInscription.vue'),
          },
          {
            path: '/inscription/ajouter-eleve',
            name: 'Inscrire un élève',
            component: () => import('@/views/inscription/NewInscription.vue'),
          },
        ],
      },
      {
        path: '/vaccination',
        name: 'Vaccination',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/vaccination/liste',
        children: [
          {
            path: '/vaccination/liste',
            name: 'Liste des vaccinations',
            component: () => import('@/views/vaccination/VaccinationList.vue'),
          },
          {
            path: '/vaccination/nouveau',
            name: 'Créer une vaccination',
            component: () => import('@/views/vaccination/VaccinationNew.vue'),
          },
        ],
      },
      {
        path: '/parametre',
        name: 'Paramètres',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/parametre/liste',
        children: [
          {
            path: '/parametre/liste',
            name: 'Liste des paramètres',
            component: () => import('@/views/parametre/ParamList.vue'),
          },
          {
            path: '/parametre/nouveau',
            name: 'Créer un paramètre',
            component: () => import('@/views/parametre/ParamNew.vue'),
          },
        ],
      },
      {
        path: '/classe',
        name: 'Classe',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/classe/liste',
        children: [
          {
            path: '/classe/liste',
            name: 'Liste de classes',
            component: () => import('@/views/classe/ClasseList.vue'),
          },
          {
            path: '/classe/nouveau',
            name: 'Créer une classe',
            component: () => import('@/views/classe/ClassNew.vue'),
          },
          {
            path: '/classe/edition',
            name: 'Editer une classe',
            component: () => import('@/views/classe/ClassNew.vue'),
          },
        ],
      },
      {
        path: '/matiere',
        name: 'Matière',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/matiere/liste',
        children: [
          {
            path: '/matiere/liste',
            name: 'Liste des matières',
            component: () => import('@/views/matiere/MatiereList.vue'),
          },
          {
            path: '/matiere/nouveau',
            name: 'Créer une matière',
            component: () => import('@/views/matiere/MatiereNew.vue'),
          },
        ],
      },
      {
        path: '/enseignant',
        name: 'Enseignant',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/enseignant/liste',
        children: [
          {
            path: '/enseignant/liste',
            name: 'Liste des enseignants',
            component: () => import('@/views/enseignant/TeacherList.vue'),
          },
          {
            path: '/enseignant/nouveau',
            name: 'Enregistrer un enseignant',
            component: () => import('@/views/enseignant/TeacherNew.vue'),
          },
        ],
      },
      {
        path: '/eleve',
        name: 'Élève',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/eleve/liste',
        children: [
          {
            path: '/eleve/liste',
            name: 'Liste des élèves',
            component: () => import('@/views/eleve/EleveList.vue'),
          },
          {
            path: '/eleve/nouveau',
            name: 'Enregistrer un élève',
            component: () => import('@/views/eleve/EleveNew.vue'),
          },
          {
            path: '/eleve/edition',
            name: 'Editer un élève',
            component: () => import('@/views/eleve/EleveNew.vue'),
          },
          {
            path: '/eleve/detail',
            name: 'Details élève',
            component: () => import('@/views/eleve/DetailsEleve.vue'),
          },
        ],
      },
      {
        path: '/parent',
        name: 'Parent',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/parent/liste',
        children: [
          {
            path: '/parent/liste',
            name: 'Liste des parents',
            component: () => import('@/views/parent/ParentList.vue'),
          },
          {
            path: '/parent/nouveau',
            name: 'Enregistrer un parent',
            component: () => import('@/views/parent/ParentNew.vue'),
          },
          {
            path: '/parent/detail',
            name: 'Details du parent',
            component: () => import('@/views/parent/DetailsParent.vue'),
          },
          {
            path: '/parent/edition',
            name: 'Editer un parent',
            component: () => import('@/views/parent/ParentNew.vue'),
          },
        ],
      },
      {
        path: '/frais-scolarite',
        name: 'Frais de scolarité',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/frais-scolarite/liste',
        children: [
          {
            path: '/frais-scolarite/liste',
            name: 'Liste des frais de scolarité',
            component: () =>
              import('@/views/frais-scolarite/ScolariteList.vue'),
          },
          {
            path: '/frais-scolarite/nouveau',
            name: 'Créer un frais de scolairité',
            component: () => import('@/views/frais-scolarite/ScolariteNew.vue'),
          },
          {
            path: '/frais-scolarite/edition',
            name: 'Editer un frais de scolairité',
            component: () => import('@/views/frais-scolarite/ScolariteNew.vue'),
          },
        ],
      },
      {
        path: '/paiement',
        name: 'Paiement',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/paiement/liste',
        children: [
          {
            path: '/paiement/liste',
            name: 'Liste',
            component: () => import('@/views/paiement/PaiementList.vue'),
          },
          {
            path: '/paiement/nouveau',
            name: 'Enregistrer un paiement',
            component: () => import('@/views/paiement/PaiementNew.vue'),
          },
          {
            path: '/paiement/detail',
            name: 'Details paiement',
            component: () => import('@/views/paiement/DetailsPaiement.vue'),
          },
          {
            path: '/paiement/edition',
            name: 'Editer un paiement',
            component: () => import('@/views/paiement/PaiementNew.vue'),
          },
        ],
      },
      {
        path: '/rapport-dettes',
        name: 'Rapport impayés',
        component: () => import('@/views/rapports/RapportDebiteur.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched[0].name == 'Accueil') {
    authGuard()
  }
  next()
})

export default router
