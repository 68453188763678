<template>
  <CFooter>
    <div>
      Complexe scolaire St Eliel du Benin
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="ms-auto">
      <span class="me-1">Powered by</span>
      <a target="_blank" href="https://euphorbiagroup.com/">Euphorbia Group</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
